import { Injectable } from '@angular/core'
import { AlertService } from './alert.service'
import { get } from 'lodash'

export type FleetVehicleCsvModeType = 'CONFIRM'

@Injectable()
export class FleetVehicleCsvUploadService {
  private fleetCompanyId: string
  private mode: FleetVehicleCsvModeType

  constructor(private alertService: AlertService) {}

  setFleetCompanyId(fleetCompanyId: string): FleetVehicleCsvUploadService {
    this.fleetCompanyId = fleetCompanyId
    return this
  }

  setMode(mode: FleetVehicleCsvModeType): FleetVehicleCsvUploadService {
    this.mode = mode
    return this
  }

  getFormDataFromVehicleFile(event): FormData {
    this.validateFleetCompanyIdAndMode()

    const element = event.target
    const file = get(element, 'files[0]')

    if (!file) {
      this.alertService.pushAlert({
        type: 'danger',
        message: 'File not found.',
      })
      return
    }

    // check if file is under 5MBs
    if (file.size > 5 * 1024 * 1024) {
      this.alertService.pushAlert({
        type: 'danger',
        message: 'File is too large!',
      })
      return
    }

    const formData = new FormData()

    formData.append('vehicles', file)
    formData.append('fleetCompanyId', this.fleetCompanyId)
    formData.append('mode', this.mode)

    return formData
  }

  private validateFleetCompanyIdAndMode() {
    if (!this.fleetCompanyId && !this.mode) {
      throw new Error('You need to set `fleetCompanyId` and `mode`!')
    }
    if (!this.fleetCompanyId) {
      throw new Error('You need to set `fleetCompanyId`!')
    }
    if (this.mode !== 'CONFIRM') {
      throw new Error('You need to type in `CONFIRM`!')
    }
  }
}
